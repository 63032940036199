import React, { Component } from 'react';
import b_ from 'b_';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import { PageWrap } from '../PageWrap';
import { BodyFixedContext } from './BodyFixedContext';
import './Layout.scss';

const b = b_.lock('Layout');

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false
    };
  }

  fixBody = () => this.setState({ fixed: true });

  releaseBody = () => this.setState({ fixed: false });

  render() {
    const { children, navItems, phones } = this.props;
    const { fixed } = this.state;

    return (
      <BodyFixedContext.Provider
        value={{
          fixed,
          lock: this.fixBody,
          release: this.releaseBody
        }}
      >
        <Helmet>
          <html lang='ru' className={b('html')} />
          <title>Оборудование для штрихкодирования и автоматизации</title>
          <link
            href='https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&subset=cyrillic'
            rel='stylesheet'
          />
          <body className={b('body', { fixed })} />
        </Helmet>
        <PageHeader
          navItems={navItems}
          onNavOpen={this.fixBody}
          onNavClose={this.releaseBody}
          phones={phones}
        />
        <main className={b('main')}>
          <PageWrap>{children}</PageWrap>
        </main>
        <PageFooter />
      </BodyFixedContext.Provider>
    );
  }
}

export default Layout;
