import React from 'react';
import b_ from 'b_';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import logo from './logo.svg';

const b = b_.lock('Logo');

const Logo = ({ className, path = '/' }) => (
  <Link className={cn(b(), className)} to={path}>
    <img src={logo} alt='logo' />
  </Link>
);

export default Logo;
