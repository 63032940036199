import React from 'react';
import b_ from 'b_';
import cn from 'classnames';
import { Link } from '../Link';
import './LinksList.scss';

const b = b_.lock('LinksList');

const LinksList = ({ as, className, data = [] }) => (
  <div className={cn(b(), className)}>
    {data.map(({ className, ...item }, i) => (
      <Link
        className={cn(b('link'), className)}
        as={as}
        key={i}
        {...item}
      />
    ))}
  </div>
);

export default LinksList;
