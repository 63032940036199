import React from 'react';
import b_ from 'b_';
import cn from 'classnames';
import { Logo } from '../Logo';
import { Link } from '../Link';
import { PageWrap } from '../PageWrap';
import { brandName, phones, footerLinks } from '../../data';
import { LinksList } from '../LinksList';
import './PageFooter.scss';

const b = b_.lock('PageFooter');

const copyright = `© 2018 ${brandName} 
Копирование информации только с разрешения правообладателя.`;

const PageFooter = ({ className }) => (
  <footer className={cn(b(), className)}>
    <PageWrap className={b('inner')}>
      <Logo className={b('logo')} />
      <div className={b('phones')}>
        {phones.map((phone, i) => (
          <Link
            className={b('phoneLink')} as='a'
            key={i}
            href={`tel:${phone}`}
          >
            {phone}
          </Link>
        ))}
      </div>
      <LinksList
        className={b('links')}
        data={footerLinks}
      />
      <span className={b('copyright')}>{copyright}</span>
    </PageWrap>
  </footer>
);

export default PageFooter;
