import React from 'react';
import b_ from 'b_';
import cn from 'classnames';

import './Button.css';

const b = b_.lock('Button');

export const Button = ({
  className,
  as: Component = 'button',
  children,
  skin = 'blue', // blue, green, transparent, white
  size, // 'm' | 'l'
  shadow,
  type = 'button',
  ...props
}) => {
  if (Component === 'button') {
    props.type = type;
  }

  return (
    <Component className={cn(b({ shadow, skin, size }), className)} {...props}>
      {children}
    </Component>
  );
};
