export const lazyRoutes = [
  ['/catalog', () => import('./pages/CatalogRoute'), true],
  ['/catalog/:code', () => import('./pages/CatalogSectionRoute')],
  ['/contacts', () => import('./pages/ContactsRoute'), true],
  ['/delivery', () => import('./pages/DeliveryNPaymentRoute'), true],
  ['/support/software', () => import('./pages/DriversRoute'), true],
  ['/support/software/:code', () => import('./pages/DriverDetailsRoute')],
  ['/', () => import('./pages/IndexRoute'), true],
  ['/common/privacy', () => import('./pages/PrivacyPolicyRoute'), true],
  ['/products/:code', () => import('./pages/ProductDetailsRoute')],
  ['/products', () => import('./pages/ProductDetailsRoute'), true],
  ['/publications', () => import('./pages/SupportPublicationsListRoute'), true],
  ['/publications/:code', () => import('./pages/SupportPublicationRoute')],
  ['/support', () => import('./pages/SupportRoute'), true],
  ['/common/agreement', () => import('./pages/UserAgreementRoute'), true],
  ['/common/warranty', () => import('./pages/WarrantyRoute'), true],
  ['/cart', () => import('./pages/CartRoute'), true],
  ['/o', () => import('./pages/OrdersRoute'), true],
  ['/o/:orderId', () => import('./pages/OrderDetailsRoute')],
  ['/404', () => import('./pages/NotFoundRoute')]
];
