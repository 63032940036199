import React from 'react';
import b_ from 'b_';
import cn from 'classnames';

import './PageWrap.css';

const b = b_.lock('PageWrap');

const PageWrap = ({ className, children }) => (
  <div className={cn(b(), className)}>{children}</div>
);

export default PageWrap;
