/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import b_ from 'b_';
import cn from 'classnames';
import { BodyFixedContext } from '../Layout';
import { Button } from '../Button';
import iconCross from './cross.svg';
import iconMenu from './menu.svg';
import './MainNav.scss';

const b = b_.lock('MainNav');

class MainNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  static contextType = BodyFixedContext;

  closeNavigation = () => this.setState({ opened: false }, () => this.context.release());

  openNavigation = () => this.setState({ opened: true }, () => this.context.lock());

  render() {
    const { className, items = [], phones = [] } = this.props;
    const { opened } = this.state;

    return (
      <>
        <Button
          className={b('opener')}
          shadow='thin'
          onClick={this.openNavigation}
        >
          <img
            style={{ display: 'block' }}
            src={iconMenu}
            width={18}
            height={18}
            alt='Открыть меню'
          />
        </Button>
        <div className={cn(b({ opened }), className)}>
          <Button
            className={b('closer')}
            shadow='thin'
            skin='white'
            onClick={this.closeNavigation}
          >
            <img
              style={{ display: 'block' }}
              src={iconCross}
              width={18}
              height={18}
              alt='Закрыть меню'
            />
          </Button>
          <ul className={b('list')}>
            {items.map(({
              children, id, name, path
            }) => (
              <li className={b('item')} key={id}>
                <Link
                  className={b('link')}
                  onClick={this.closeNavigation}
                  to={path}
                >
                  {name}
                </Link>
                {children && (
                  <ul className={b('list', { sub: true })}>
                    {children.map(({ id, name, path }) => (
                      <li className={b('subItem')} key={id}>
                        <Link
                          className={b('link', { sub: true })}
                          onClick={this.closeNavigation}
                          to={path}
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          {phones[0] && (
            <a
              className={b('phoneLink')}
              href={`tel:${phones[0]}`}
            >
              {phones[0]}
            </a>
          )}
        </div>
      </>
    );
  }
}

export default MainNav;
