/* eslint-disable */
import React from 'react';
import { lazyLoad } from './LazyComponent';

const PopupsImport = lazyLoad(
  () => import(/* webpackChunkName: "popups" */'./Popups'),
  null
);

export class LazyPopups extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  load = () => this.setState({ loaded: true });

  componentDidMount() {
    if (document.readyState === 'complete' || document.readyState === 'loaded') {
      this.load();
    } else {
      window.addEventListener('load', this.load);
    }
  }

  render() {
    return this.state.loaded
      ? <PopupsImport />
      : null;
  }
}
