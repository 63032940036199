import React from 'react';
import b_ from 'b_';
import cn from 'classnames';
import { Logo } from '../Logo';
import { PageWrap } from '../PageWrap';
import { MainNav } from '../MainNav';
import './PageHeader.scss';

const b = b_.lock('PageHeader');

const PageHeader = ({
  className,
  navItems,
  onNavOpen,
  onNavClose,
  phones
}) => (
  <header className={cn(b(), className)}>
    <PageWrap className={b('inner')}>
      <Logo className={b('logo')} />
      <MainNav
        items={navItems}
        onNavOpen={onNavOpen}
        onNavClose={onNavClose}
        phones={phones}
      />
    </PageWrap>
  </header>
);

export default PageHeader;
