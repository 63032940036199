import React from 'react';
import { withRouter } from 'react-router-dom';

// TODO: sync between tabs via localStorage

export const OrderContext = React.createContext();

const initialState = {
  order: {
    name: '',
    phone: '',
    city: null, // { value: string, city_fias_id: string, settlement_fias_id: string }
    deliveryId: null, // delivery ID
    deliveryOfferId: null, // delivery offer id, example office code
    paymentMethodId: null // payment method id
  },
  cart: [] // Array<{ productId, quantity }>
};

const reduceAddToCart = (cart = [], productId) => {
  let isExist = false;

  const newCart = cart.reduce(
    (acc, item) => {
      if (item.productId === productId) {
        isExist = true;
        acc.push({ ...item, quantity: item.quantity + 1 });
      } else {
        acc.push(item);
      }

      return acc;
    },
    []
  );

  if (!isExist) newCart.unshift({ productId, quantity: 1 });
  return newCart;
};

export const OrderProvider = withRouter(class extends React.Component {
  constructor(props) {
    super(props);

    let restored;
    if (window.localStorage) {
      restored = JSON.parse(window.localStorage.getItem('posprinters_order')) || {};
    }

    this.state = {
      ...initialState,
      ...restored,
      formState: 'cart', // 'cart' | 'sending' | 'error'
      addToCart: this.addToCart,
      deleteProduct: this.deleteProduct,
      resetOrderFormState: this.resetOrderFormState,
      updateOrder: this.updateOrder,
      increaseProductQuantity: this.increaseProductQuantity,
      decreaseProductQuantity: this.decreaseProductQuantity,
      sendOrder: this.sendOrder
    };
  }

  updateOrder = (field, value) => this.setState(
    state => ({ order: { ...state.order, [field]: value } })
  );

  sendOrder = () => {
    const { cart, order } = this.state;

    this.setState({ formState: 'sending' });

    fetch(
      '/api/orders',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ ...order, cart })
      }
    )
      .then(response => {
        if (response.status === 200) {
          response
            .json()
            .then(({ orderId }) => {
              this.props.history.replace(`/o/${orderId}`);
              this.setState({ cart: [], formState: 'cart' });
            });
        } else {
          this.setState({ formState: 'error' });
        }
      })
      .catch(() => {
        this.setState({ formState: 'error' });
      });
  }

  resetOrderFormState = () => this.setState({ formState: 'cart' });

  deleteProduct = index => this.setState(({ cart }) => ({
    cart: cart.filter((_, i) => i !== index)
  }))

  changeProductQuantity = (index, newQuantity) => this.setState(({ cart }) => ({
    cart: cart.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          quantity: newQuantity > 1 ? newQuantity : 1
        };
      }

      return item;
    })
  }))

  increaseProductQuantity = index => {
    this.changeProductQuantity(index, this.state.cart[index].quantity + 1);
  }

  decreaseProductQuantity = index => {
    this.changeProductQuantity(index, this.state.cart[index].quantity - 1);
  }

  addToCart = productId => this.setState(
    ({ cart }) => ({
      cart: reduceAddToCart(cart, productId)
    })
  );

  componentDidUpdate(_, { cart: prevCart, order: prevOrder }) {
    const { order, cart } = this.state;

    if ((prevCart !== cart || prevOrder !== order) && window.localStorage) {
      window.localStorage.setItem('posprinters_order', JSON.stringify({ order, cart }));
    }
  }

  render() {
    return (
      <OrderContext.Provider value={this.state}>{this.props.children}</OrderContext.Provider>
    );
  }
});
