import React from 'react';
import b_ from 'b_';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import './Link.scss';

const b = b_.lock('Link');

const Link = ({
  as,
  className,
  children,
  ...props
}) => {
  const Component = as || RouterLink;
  return (<Component className={cn(b(), className)} {...props}>{children}</Component>);
};

export default Link;
