import React from 'react';
import { Button } from './Button';

const RoutePreloader = () => 'Загрузка...';

const LoadingError = ({ onRetryClick }) => (
  <>
    <p>Ошибка загрузки</p>
    <Button onClick={onRetryClick}>Попробовать починить</Button>
  </>
  // TODO: Add mainpage or catalog link
  // or automatically reload page on error repeat

  // TODO: add description on russian
);

export const lazyLoad = (
  dynamicImport,
  Fallback = RoutePreloader
) => class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, Component: null };
    this.load();
  }

  load = () => {
    let wasError = false;
    if (this.state.hasError) {
      wasError = true;
      this.setState({ hasError: false });
    }

    dynamicImport()
      .then(({ default: Component }) => this.setState({ Component }))
      .catch(
        () => wasError
          ? window.location.reload()
          : this.setState({ hasError: true })
      );
  }

  render() {
    const { Component, hasError } = this.state;

    return Component
      ? <Component {...this.props} />
      : hasError
        ? <LoadingError onRetryClick={this.load} />
        : Fallback && <Fallback />;
  }
};
