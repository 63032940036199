import catalogSections from '../cachedData/catalogSections';

export const menuItems = [
  {
    id: 4132,
    name: 'Каталог',
    path: '/catalog',
    children: catalogSections
  },
  {
    id: 8662,
    name: 'Доставка и оплата',
    path: '/delivery'
  },
  {
    id: 1236,
    name: 'Поддержка',
    path: '/support',
    children: [
      { id: 85633, name: 'Драйверы и программы', path: '/support/software' },
      { id: 67353, name: 'Инструкции', path: '/publications' }
    ]
  },
  {
    id: 9752,
    name: 'Контакты',
    path: '/contacts'
  }
];
