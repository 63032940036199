import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';

import { menuItems, phones, notificationPanelText } from '../data';
import '../global/reset.scss';
import { Layout } from './Layout';
import ScrollToTop from './ScrollToTop';
import { OrderProvider } from './OrderContext';
import { lazyLoad } from './LazyComponent';
import { LazyPopups } from './LazyPopups';
import { LayoutNotificationPanel } from './LayoutNotificationPanel';

export const App = ({ route, lazyRoutes = [] }) => {
  const [_, load404] = lazyRoutes.find(([path]) => path === '/404') || []; // eslint-disable-line no-unused-vars

  return (
    <Router>
      <ScrollToTop>
        <OrderProvider>
          <Helmet>
            <meta property='og:site_name' content='Posprinters.ru' />
          </Helmet>
          <Layout navItems={menuItems} phones={phones}>
            {!!notificationPanelText && (
              <LayoutNotificationPanel text={notificationPanelText} />
            )}
            <Switch>
              {route && (
                <Route
                  key={route[0]}
                  path={route[0]}
                  exact={route[2]}
                  component={route[1]}
                />
              )}
              {lazyRoutes.map(([path, loadFn, exact]) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  component={lazyLoad(loadFn)}
                />
              ))}
              <Route
                key='*'
                path='*'
                component={route[0] === '/404' ? route[1] : lazyLoad(load404)}
              />
            </Switch>
          </Layout>
          <LazyPopups />
        </OrderProvider>
      </ScrollToTop>
    </Router>
  );
};
