export const domain = 'posprinters.ru';
export const domainNProto = `https://${domain}`;
export const brandName = 'PosPrinters.ru';
export const email = `hi@${domain}`;
// export const freeDeliveryFromRUB = '15000 р.';
export const returnWoReasonDays = 7;
export const phones = ['+7 928 884 61 42'];
export const address = '350063, г. Краснодар, ул. Гимназическая, 14, подъезд 2, офис 2';
export const coordinates = [45.026531, 38.963044];
export const workTime = '';

export const legal = `ИП Бабаев Роман Геннадьевич
ИНН 231151028893
ОГРН 321237500040276 от 09.02.2021`;

export const footerLinks = [
  { to: '/contacts', children: 'Контактные данные' },
  // { to: '/contactform', children: 'Связаться с нами' },
  { to: '/common/agreement', children: 'Пользовательское соглашение' },
  { to: '/common/warranty', children: 'Гарантия и возврат' },
  { to: '/common/privacy', children: 'Политика конфиденциальности' }
];

export const headings = {
  '/cart': 'Корзина',
  '/catalog': 'Каталог',
  '/contacts': 'Контактые данные',
  '/common/agreement': 'Пользовательское соглашение',
  '/common/privacy': 'Политика в отношении обработки персональных данных',
  '/common/warranty': 'Гарантия и возврат',
  '/delivery': 'Доставка и оплата',
  '/publications': 'Инструкции и руководства',
  '/support': 'Поддержка',
  '/support/software': 'Драйверы и программы'
};

export const notificationPanelText = null;
// export const notificationPanelText = `
// В связи с экономической обстановкой мы временно не обрабатываем заказы.
// Вы можете оставить заказ и мы свяжемся с вами, когда вернемся к работе.
// Указанные на сайте цены не актуальны.
// `.trim();
